<template>
  <div id="app">
    <header class="header" v-if="!$route.meta.hideInfo">
      <div class="container">
        <router-link :to="{ name: 'Home' }">
          <img src="./assets/logo.svg" alt="logotype" />
        </router-link>
        <div class="header-menu">
          <router-link :to="{ name: 'Courses' }">Каталог</router-link>
        </div>
      </div>
    </header>
    <router-view />
    <footer class="footer" v-if="!$route.meta.hideInfo">
      <div class="container">
        <div class="footer-logo">
          <img src="./assets/logo.svg" alt="logotype" />
        </div>
        <ul>
          <li v-for="item in footer_menu" :key="item.link">
            <router-link :to="{ name: item.link }">
              {{ item.title }}
            </router-link>
          </li>
          <li class="payments">
            <img src="./assets/payments/payments.svg" alt="" />
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "app",

  data() {
    return {
      footer_menu: [
        { title: "Контакты", link: "Contacts" },
        { title: "Условия использования", link: "Rules" },
        { title: "Политика конфиденциальности", link: "Policy" },
        { title: "Безопасность платежей", link: "PaymentSecure" },
      ],
    };
  },

  methods: {
    ...mapActions({
      loadSliderCourses: "loadSliderCourses",
      loadCourses: "loadCourses",
    }),
  },

  beforeMount() {
    this.loadSliderCourses();
    this.loadCourses();
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  padding: $base-space * 4 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  > .container {
    display: grid;
    grid-template-columns: 54px 1fr;
    grid-gap: $base-space * 4;
    align-items: center;
  }
  &-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > a {
      color: $black;
      text-decoration: none;
      @include button1();
      &:hover {
        color: $pr1;
      }
    }
  }
}

.footer {
  border-top: 1px solid $black5;
  padding: ($base-space * 7) 0;
  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
    > ul {
      @include button1();
      color: $black;
      @media (max-width: 580px) {
        width: 100%;
      }
      > li {
        text-align: right;
        @media (max-width: 580px) {
          text-align: center;
        }
        &:not(:last-of-type) {
          margin: 0 0 ($base-space * 2);
        }
        > a {
          color: $black;
          text-decoration: none;
          transition: 0.3s;
          &:hover {
            color: $pr1;
          }
        }
      }
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    @media (max-width: 580px) {
      width: 100%;
      justify-content: center;
    }
  }
}

.payments {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
</style>
